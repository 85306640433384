<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <section class="header point_success">
                <div class="icon iconButton"></div>
                <span class="fL">{{pointName}}消費</span>
                <a
                    class="icon iconButton black"
                    @click.prevent="$router.push('/home')"
                >
                    <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
                </a>
            </section>
            <!-- Main -->
            <section class="main point_success">
                <div class="tabs line fM rounded">
                    <a
                        class="tab flexV width center"
                        @click.prevent="$router.push('/record')"
                    >
                        <span class="fS gray">{{pointName}}</span>
                        <div class="flexH">
                            <div class="img">
                                <img :src="pointImage" />
                            </div>
                            <span class="fM bold">{{
                                displayUserPointBalance
                            }}</span>
                        </div>
                    </a>
                    <div class="lineV"></div>
                    <a class="tab flexV width center deactive" @click.prevent>
                        <span class="fS">票券</span>
                        <div class="flexH">
                            <img src="@/assets/icon/ticket-off.svg">
                            <span class="fM bold">5</span>
                        </div>
                    </a>
                    <div class="lineV"></div>
                    <a class="tab flexV width center deactive" @click.prevent>
                        <span class="fS">集章</span>
                        <div class="flexH">
                            <img src="@/assets/icon/stamp-off.svg">
                            <span class="fM bold">未啟用</span>
                        </div>
                    </a>
                </div>
                <div class="info flexV width">
                    <div class="title flexV width center">
                        <div class="icon success">
                            <font-awesome-icon
                                icon="fa-solid fa-circle-check"
                                size="4x"
                            />
                        </div>
                        <span class="fXL success">入帳成功</span>
                    </div>
                    <div class="detail flexV width margin">
                        <div class="flexH width between">
                            <span class="fL gray">活動名稱</span>
                            <span class="fL">{{ name }}</span>
                        </div>
                        <div class="flexH width between">
                            <span class="fL gray">交易時間</span>
                            <span class="fL">{{tsToDatetime(redeemedTs)}}</span>
                        </div>
                        <div class="flexH width between">
                            <span class="fL gray">獲得點數</span>
                            <div class="flexH">
                                <div class="img"><img src="@/assets/icon/point.svg"></div>
                                <span class="fL">{{ value }}</span>
                            </div>
                        </div>
                        <div class="flexH width between">
                            <span class="fL gray">點數餘額</span>
                            <div class="flexH">
                                <div class="img"><img src="@/assets/icon/point.svg"></div>
                                <span class="fL">{{
                                    displayUserPointBalance
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flexH width middle">
                        <a class="button rounded gray" @click.prevent="$router.push('/record')">
                            <span class="fM">前往點數頁</span>
                        </a>
                        <a class="button rounded" @click.prevent="$router.push('/home')">
                            <span class="fM">回首頁</span>
                        </a>
                    </div>
                </div>
                
            </section>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            name: this.$route.query.name,
            gainPoints: this.$route.query.points,
            value: this.$route.query.type == "point" ? this.$route.query.value : 0,
            redeemedTs: this.$route.query.redeemedTs
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    created() {
        this.getUserPoints()
            .then((res) => {
                this.$store.commit("updateUserPoints", res);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    },
    mounted() {
        console.log("mounted!!!!");
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        pointImage() {
            return process.env.VUE_APP_POINT_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_POINT_IMAGE_PATH}`) : require("@/assets/icon/point.svg");
        }, 
        pointName() {
            return process.env.VUE_APP_POINT_NAME?.trim() ? process.env.VUE_APP_POINT_NAME?.trim() : '紅利桃子';
        }, 
        displayPointExpireTime() {
            if (
                this.userPoints.pointPeriods &&
                this.userPoints.pointPeriods.length > 0
            ) {
                return this.tsToDate(this.userPoints.pointPeriods[0].endTs);
            } else {
                return "";
            }
        },
        displayUserPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
    },
    methods: {
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        getUserPoints() {
            console.log("this.userId: ", this.user.userId);
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
};
</script>

